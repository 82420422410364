import moment from 'moment';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-ja';
import 'jquery-ui/themes/base/all.css';

// 和暦に変換する関数
function toJapaneseDate(gregorianDate) {
    let date = moment(gregorianDate, 'YYYY-MM-DD');
    let year = date.year();
    let month = date.month() + 1;
    let day = date.date();

    let era = '';
    let japaneseYear = year;

    if (year >= 2019) {
        era = '令和';
        japaneseYear = year - 2018;
    } else if (year >= 1989) {
        era = '平成';
        japaneseYear = year - 1988;
    } else if (year >= 1926) {
        era = '昭和';
        japaneseYear = year - 1925;
    } else if (year >= 1912) {
        era = '大正';
        japaneseYear = year - 1911;
    } else if (year >= 1868) {
        era = '明治';
        japaneseYear = year - 1867;
    } else {
        return '変換不可'; // これ以前の年はサポート外
    }

    return `${era}${japaneseYear}年${month}月${day}日`;
}

// 西暦に変換する関数
function toGregorianDate(japaneseDate) {
    const eras = {
        '令和': 2019,
        '平成': 1989,
        '昭和': 1926,
        '大正': 1912,
        '明治': 1868
    };

    let eraMatch = japaneseDate.match(/(令和|平成|昭和|大正|明治)/);
    if (!eraMatch) {
        return null; // サポート外の和暦
    }

    let era = eraMatch[0];
    let year = parseInt(japaneseDate.match(/(\d+)年/)[1]);
    let month = parseInt(japaneseDate.match(/(\d+)月/)[1]);
    let day = parseInt(japaneseDate.match(/(\d+)日/)[1]);

    year += eras[era] - 1; // 和暦年を西暦に変換

    return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
}

// 日付が有効かチェックする関数
function isValidDate(dateString) {
    return moment(dateString, 'YYYY-MM-DD', true).isValid();
}

// ja-date-input初期化関数
function initializeJaDateInputs() {
    $('.ja-date-input').each(function() {
        let dateText = $(this).val();
        let hiddenFieldName = $(this).attr('name');
        $(this).attr('name', 'display_' + hiddenFieldName);

        let hiddenField = $('<input>', {
            type: 'hidden',
            class: 'hidden-date',
            name: hiddenFieldName,
            value: dateText
        });

        $(this).after(hiddenField);

        if (dateText && isValidDate(dateText)) {
            $(this).val(toJapaneseDate(dateText));
            $(this).data('original-value', dateText); // 元の西暦を保存
        }
    });

    $('.ja-date-input').datepicker({
        dateFormat: 'yy-mm-dd',
        onSelect: function(dateText, inst) {
            $(inst.input).val(toJapaneseDate(dateText));
            $(inst.input).data('original-value', dateText);
            $(inst.input).next('.hidden-date').val(dateText);
        },
        onClose: function(dateText, inst) {
            let inputElement = $(inst.input);
            if (dateText && isValidDate(dateText)) {
                inputElement.val(toJapaneseDate(dateText));
                inputElement.data('original-value', dateText);
                inputElement.next('.hidden-date').val(dateText);
            }
        }
    });

    // フォーカスが外れた時に和暦表示に変換
    $('.ja-date-input').on('blur', function() {
        let dateText = $(this).data('original-value');
        if (dateText && isValidDate(dateText)) {
            $(this).val(toJapaneseDate(dateText));
        }
    });

    // フォーカス時に西暦表示に戻す
    $('.ja-date-input').on('focus', function() {
        let dateText = $(this).data('original-value');
        if (dateText && dateText !== '-') {
            $(this).val(dateText);
        }
    });

    // フォーム送信前にhiddenフィールドを更新
    $('form').on('submit', function() {
        $('.ja-date-input').each(function() {
            let dateText = $(this).data('original-value');
            if (dateText && dateText !== '-') {
                $(this).next('.hidden-date').val(dateText);
            }
        });
    });

    // AJAX送信前にhiddenフィールドを更新
    $(document).ajaxSend(function() {
        $('.ja-date-input').each(function() {
            let dateText = $(this).data('original-value');
            if (dateText && dateText !== '-') {
                $(this).next('.hidden-date').val(dateText);
            }
        });
    });

    // 日本語ロケールの設定
    $.datepicker.setDefaults($.datepicker.regional['ja']);
}

// ja-month-input初期化関数
function initializeJaMonthInputs() {
    $('input.ja-month-input').datepicker({
        changeMonth: true,
        changeYear: true,
        showButtonPanel: true,
        dateFormat: 'yy/mm',
        beforeShow: function(input, inst) {
            $(this).datepicker('widget').addClass('month-year-picker');
            $(this).datepicker('widget').find('.ui-datepicker-calendar').hide();
        },
        onClose: function(dateText, inst) {
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
            $(this).datepicker('setDate', new Date(year, month, 1));
            var date = new Date(year, month, 1);
            var japaneseDate = convertToJapaneseDate(date);
            $(this).val(japaneseDate);
        }
    }).focus(function() {
        $(".ui-datepicker-calendar").hide();
    });
}

// 西暦から和暦に変換する関数
function convertToJapaneseDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    let era;
    let japaneseYear;

    if (year >= 2019) {
        era = '令和';
        japaneseYear = year - 2018;
    } else if (year >= 1989) {
        era = '平成';
        japaneseYear = year - 1988;
    } else if (year >= 1926) {
        era = '昭和';
        japaneseYear = year - 1925;
    } else if (year >= 1912) {
        era = '大正';
        japaneseYear = year - 1911;
    } else {
        era = '明治';
        japaneseYear = year - 1867;
    }

    return `${era}${japaneseYear}年${month}月`;
}

// 初期化
$(document).ready(function() {
    initializeJaDateInputs();
    initializeJaMonthInputs();
});
