import $ from 'jquery';

$(document).ready(function () {
    var isMouseDown = false,
        startX, scrollX;

    $('.js-drag-scroll').mousedown(function (e) {
        // マウスがクリックされた要素がinput, textarea, selectタグである場合には、この処理を無効化します
        if (['input', 'textarea', 'select'].includes(e.target.tagName.toLowerCase())) {
            return;
        }
        if (e.target.tagName.toLowerCase() === 'select') {
            return;
        }
        if ($(e.target).hasClass('selectable')) {
            return;
        }

        isMouseDown = true;
        $(this).css('cursor', 'grabbing');

        startX = e.clientX;
        scrollX = this.scrollLeft;

        return false;
    }).mousemove(function (e) {
        if (isMouseDown) {
            this.scrollLeft = scrollX + startX - e.clientX;
            // this.scrollTop = scrollY + startY - e.clientY; // Y方向のスクロールを無効化
            return false;
        }
    }).mouseup(function (e) {
        isMouseDown = false;
        $(this).css('cursor', 'default');
    }).mouseleave(function (e) {
        isMouseDown = false;
        $(this).css('cursor', 'default');
    });
});
