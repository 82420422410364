import $ from 'jquery';
import { Html5Qrcode } from "html5-qrcode";

$(document).ready(function() {
    let attendeeCount = 0;
    let lastScannedCode = null;
    let scanningPaused = false;

    $('#add-attendee-btn').click(function() {
        if (attendeeCount >= 2) {
            alert('追加のご招待は最大3人までです');
            return;
        }

        attendeeCount++;
        const attendeeHtml = `
            <div class="c-form-row__flex js-add-names">
                <input type="text" name="visitors[${attendeeCount}][name_sei]" placeholder="姓">
                <input type="text" name="visitors[${attendeeCount}][name_mei]" placeholder="名">
            </div>
        `;

        $('.js-add-names:last').after(attendeeHtml);
    });

    function initializeQrCodeScanner() {
        const html5QrCode = new Html5Qrcode("qr-reader");

        const successAudio = new Audio('/audio/success.mp3');

        function onScanSuccess(qrCodeMessage) {
            if (scanningPaused || qrCodeMessage === lastScannedCode) {
                return;
            }

            scanningPaused = true;
            lastScannedCode = qrCodeMessage;

            const invitationId = qrCodeMessage;

            const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            fetch('/invitation/checkin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken
                },
                body: JSON.stringify({ invitation_id: invitationId })
            })
            .then(response => response.json())
            .then(data => {
                const resultElement = document.getElementById('qr-reader-results');
                resultElement.classList.remove('hide');
                if (data.success) {
                    resultElement.innerText = data.success;
                    successAudio.play();
                } else {
                    resultElement.innerText = 'エラー: ' + data.error;
                }

                setTimeout(() => {
                    resultElement.classList.add('hide');
                }, 4000);

                setTimeout(() => {
                    resultElement.innerText = '';
                    scanningPaused = false;
                }, 5000);
            })
            .catch(error => {
                console.error('Error:', error);
                scanningPaused = false;
            });
        }

        let errorDisplayTimeout = null;

        function onScanError(errorMessage) {
            if (errorMessage.includes("NotFoundException")) {
                return;
            }

            if (errorDisplayTimeout) return;

            const errorDisplay = document.getElementById('qr-reader-error');
            if (errorDisplay) {
                errorDisplay.innerText = `エラー: ${errorMessage}`;
            }
            console.error(errorMessage);

            errorDisplayTimeout = setTimeout(() => {
                errorDisplayTimeout = null;
            }, 2000);
        }

        html5QrCode.start(
            { facingMode: "environment" },
            {
                fps: 10,
                qrbox: { width: 250, height: 250 }
            },
            onScanSuccess,
            onScanError
        ).catch(err => {
            document.getElementById('qr-reader-results').innerText = 'カメラの起動に失敗しました。';
            console.error('QRコードリーダーの起動に失敗しました:', err);
        });
    }

    if ($('#qr-reader').length > 0) {
        initializeQrCodeScanner();
    }
});
