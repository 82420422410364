document.addEventListener('livewire:load', function () {
    // 編集モードに入ったときに input フィールドに自動フォーカス
    Livewire.hook('message.processed', (message, component) => {
        let inputField = document.querySelector('.editable-input');
        if (inputField) {
            inputField.focus();  // フォーカスを自動で設定
        }
    });

    // フラッシュメッセージの表示
    Livewire.on('flashMessage', message => {
        const flashMessageContainer = document.getElementById('flash-messages');
        flashMessageContainer.innerHTML = `
            <div class="alert alert-success">${message}</div>
        `;
        setTimeout(() => {
            flashMessageContainer.innerHTML = '';  // 3秒後にメッセージを消す
        }, 3000);
    });

});
