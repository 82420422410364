import $ from 'jquery';

$(function() {
    var $window = $(window);
    var $app = $('#app');
    if ($app.length) {

        var appOffset = $app.offset().top;
        //console.log('appの上部:', appOffset);  // appの上部をログ出力

        // 各スティッキーヘッダーの初期データを設定
        $('.js-sticky-head').each(function() {
            var $this = $(this);
            $this.data('originalOffset', $this.offset().top);
        });

        $window.scroll(function() {
            var scrollTop = $window.scrollTop();
            // console.log('スクロール上部:', scrollTop);  // スクロール上部をログ出力

            $('.c-new-layouts-contents').each(function() {
                var $this = $(this);
                var topBoundary = $this.offset().top;
                var bottomBoundary = topBoundary + $this.outerHeight();
                var $stickyHeader = $this.find('.js-sticky-head');
                var originalOffset = $stickyHeader.data('originalOffset');

                // console.log('上部の境界:', topBoundary);  // 上部の境界をログ出力
                // console.log('下部の境界:', bottomBoundary);  // 下部の境界をログ出力

                if (scrollTop >= originalOffset - appOffset && scrollTop <= bottomBoundary - appOffset) {
                    var newOffset = Math.max(scrollTop - originalOffset + appOffset, 0);
                    // console.log('新しいオフセット:', newOffset);  // 新しいオフセットをログ出力
                    $stickyHeader.css({
                        'transform': 'translateY(' + newOffset + 'px)',
                        'position': 'relative',
                        'z-index': 100 // 適切なz-indexを設定
                    });
                } else {
                    $stickyHeader.css({
                        'transform': '',
                        'position': '',
                        'z-index': '' // デフォルトに戻す
                    });
                }
            });
        });
    }
});

// $(window).on('load resize', function() {
//     $('.right-content__row').each(function(index) {
//         let rightRowHeight = $(this).height(); // 右側の行の高さを取得
//         let correspondingLabel = $('.left-content .c-cell--label').eq(index); // 対応する左側のラベルを取得
//         correspondingLabel.height(rightRowHeight); // ラベルの高さを行の高さに設定
//     });
// });

